<template>
  <div class="site">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item>TRTC设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="form" label-width="120px">
        <el-form-item label="sdkAppid">
          <el-input v-model="form.sdkAppid"></el-input>
        </el-form-item>
        <el-form-item label="sdkKey">
          <el-input v-model="form.sdkKey"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trtc',
  data () {
    return {
      form: {
        sdkAppid: '',
        sdkKey: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取信息
    async getInfo () {
      const { data: res } = await this.$http.get('trtc')
      if (res.status === 200) {
        this.form.sdkAppid = res.data.sdkAppid
        this.form.sdkKey = res.data.sdkKey
      } else {
        this.$message.error(res.msg)
      }
    },
    // 提交表单
    async onSubmit () {
      const { data: res } = await this.$http.post('trtc', this.form)
      if (res.status === 200) {
        this.$message.success(res.msg)
        this.$refs.formRef.resetFields()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>

</style>
